import {
  wstETHBooster_ADDRESS_STRATEGY_ID,
  multiplyETH_ADDRESS_STRATEGY_ID,
  shortETH_ADDRESS_STRATEGY_ID,
} from "@meta";

export const faqsData = {
  [wstETHBooster_ADDRESS_STRATEGY_ID]: [
    {
      question: <>Am I exposed to ETH price with this ILM?</>,
      answer: (
        <>
          No. Since wstETH and ETH are correlated assets, regardless of ETH price movement, you earn staking fees from
          holding a larger wstETH balance.
        </>
      ),
    },
    {
      question: <>Where does the yield come from with this ILM?</>,
      answer: (
        <>
          APY comes from staking fee rewards generated by holding wstETH. Participants in this ILM are also eligible for
          wstETH bonus rewards.
        </>
      ),
    },
    {
      question: <>Are there any costs with this ILM?</>,
      answer: (
        <>
          To achieve the 3x loop, ETH is swapped into wstETH. There are costs associated from Dexes to swap as well as
          slippage, however Seamless does not charge any extra fees. These costs occur on deposit & withdrawal from the
          ILM.
        </>
      ),
    },
    {
      question: <>Where can I see my rewards and my position balance?</>,
      answer: <>Head to the “My Positions” Tab to see your ILM LP Token Balance and underlying wstETH Balance.</>,
    },
  ],
  [multiplyETH_ADDRESS_STRATEGY_ID]: [
    {
      question: <>Am I exposed to ETH price with this ILM?</>,
      answer: <>Yes. As ETH increases in price, returns are magnified in bullish market conditions.</>,
    },
    {
      question: <>Are there any costs with this ILM?</>,
      answer: (
        <>
          To achieve the 1.5x loop, USDC is swapped into ETH. There are costs associated from Dexes to swap as well as
          slippage, however Seamless does not charge any extra fees. These costs occur on deposit & withdrawal from the
          ILM.
        </>
      ),
    },
    {
      question: <>Where can I see my rewards and my position balance?</>,
      answer: <>Head to the “My Positions” Tab to see your ILM LP Token Balance and underlying ETH Balance.</>,
    },
  ],
  [shortETH_ADDRESS_STRATEGY_ID]: [
    {
      question: <>Am I exposed to ETH price with this ILM?</>,
      answer: (
        <>Yes. As ETH decreases in price in bearish market conditions, returns are magnified with this Short ILM.</>
      ),
    },
    {
      question: <>Are there any costs with this ILM?</>,
      answer: (
        <>
          To achieve the 1.5x loop, ETH is swapped into USDC. There are Dex and slippage costs associated with swapping,
          however Seamless does not charge any extra fees. These costs occur on deposit & withdrawal from the ILM.
        </>
      ),
    },
    {
      question: <>Where can I see my rewards and my position balance?</>,
      answer: <>Head to the “My Positions” Tab to see your ILM LP Token Balance and underlying USDC Balance.</>,
    },
  ],
};
