export function pxToRem(px: number, baseFontSize = 16) {
  return `${px / baseFontSize}rem`;
}

export const fontSizes = {
  display1: pxToRem(32),
  h1: pxToRem(32),
  h2: pxToRem(21),
  h3: pxToRem(18),
  h4: pxToRem(16),
  body1: pxToRem(14),
  body2: pxToRem(14),
  subheader1: pxToRem(14),
  subheader2: pxToRem(12),
  subheader2Light: pxToRem(12),
  description: pxToRem(14),
  buttonL: pxToRem(16),
  buttonM: pxToRem(14),
  buttonS: pxToRem(10),
  helperText: pxToRem(10),
  tooltip: pxToRem(12),
  main21: pxToRem(21),
  secondary21: pxToRem(21),
  main16: pxToRem(16),
  secondary16: pxToRem(16),
  main14: pxToRem(14),
  secondary14: pxToRem(14),
  main12: pxToRem(12),
  secondary12: pxToRem(12),
  caption: pxToRem(12),
  bold7: pxToRem(40),
  bold6: pxToRem(32),
  bold5: pxToRem(24),
  bold4: pxToRem(20),
  bold3: pxToRem(16),
  bold2: pxToRem(14),
  bold1: pxToRem(12),
  bold: pxToRem(10),
  medium7: pxToRem(40),
  medium6: pxToRem(32),
  medium5: pxToRem(24),
  medium4: pxToRem(20),
  medium3: pxToRem(16),
  medium2: pxToRem(14),
  medium1: pxToRem(12),
  medium: pxToRem(10),
  regular7: pxToRem(40),
  regular6: pxToRem(32),
  regular5: pxToRem(24),
  regular4: pxToRem(20),
  regular3: pxToRem(16),
  regular2: pxToRem(14),
  regular1: pxToRem(12),
  regular: pxToRem(10),
};
